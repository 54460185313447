<template>
	<div class="box">
		<Title title="平台帮助" />

		<div class="ct_box" v-html="content">
			<!-- 内容待定 -->
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	mounted() {
		this.curlGet('/api/article/list', {
			cate_id: 6,

		}).then(res => {
			console.log(res);
			if (res.data.code) {
				this.content = res.data.data[0].content
			}
		})
	},
	data() {
		return {
			content: '',
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Help'
</style>